// component
import SvgColor from '../../../components/svg-color';


// const icon = (name) => <SvgColor src={`/assets/icons/${name}.png`} sx={{ width: 1, height: 1 }} />;
const icon = (name) => <img src={`/assets/icons2/${name}.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/home',
    icon: icon('dashboard'),
  },
  {
    title: 'Pitches',
    //path: '/dashboard/pitches',
    path: '/dashboard/pitches',
    icon: icon('teacher'),
  },
  {
    title: 'Deals',
    path: '/dashboard/deals',
    icon: icon('student'),
  },
 

  
  {
    title: 'Settings',
     path: '/dashboard/settings',
    //path: '#',
    icon: icon('settings'),
  },

  {
    title: 'Logout',
   path: '/logout',
   
    icon: icon('logout'),
  },
];

export default navConfig;
