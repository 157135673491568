import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Divider, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import redboy from 'src/assets/images/redboy.jpeg';
import greenboy from 'src/assets/images/greenboy.jpeg';
import athlete from 'src/assets/images/athlete.jpeg';
import amfootball from 'src/assets/images/amfootball.jpeg'




const columns = [
  /*{
    field: 'id',
    headerName: '#', 
    width: 250,
    renderCell: (params) => {
    },
  },*/
  {
    field: 'studentName', 
    headerName: '# Brands',
    width: 300,
    renderCell: (params) => {
      const fullName = `${params.row.fname} ${params.row.lname}`;
      return <div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"1.5rem",width:"60%"}}>
        
        <div style={{display:"flex",alignItems:"center",justifyContent:"flex-start"}}><div>{/*THE NUMBERS SHPULD GO HERE */}</div> <img src={params.row.image/*params.row.id === '0S91dTHhu7t0Zc6645Gb'?redboy:params.row.id === '75LPiOJKwtndeC67o5d3'?greenboy:params.row.id === '8Gnbs3WPwJ7ZzzvHgORs'?athlete:amfootball*/} style ={{height:"40px",width:"50px",borderRadius:"8px"}}/></div>
      
       <span style={{display:"flex",alignItems:"center",justifyContent:"flex-start",textAlign:"left"}}>{fullName}</span>

      </div>;
    },
  },
  { field: 'class', headerName: 'Deal', width: 300, renderCell: (params) => {
    return <div>{params.row.class}</div>;
  },  },
  { field: 'amount', headerName: 'Amount',  width: 300, renderCell: (params) => {
    return <div>{params.row.amount}</div>;
  }, },
  { field: 'paymentStatus', headerName: 'Status', width: 350, renderCell: (params) => {
    return <div>{params.row.paymentStatus && params.row.paymentStatus }</div>;
  }, },
  {
    field: 'actions',
    headerName: '',
    width: 300,
  },
];

export default function StudentCampaignStats({ students }) {
  const navigate = useNavigate();
  const handleActionClick = (student) => {
    navigate('/dashboard/edit-student', { state: { student } });
  };


  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={students}
        columns={columns.map((col) => {
          if (col.field === 'actions') {
            return {
              ...col,
              renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    onClick={() => handleActionClick(params?.row)}
                    variant="contained"
                    style={{ minWidth: '105px', backgroundColor: "#D72A34", marginRight: '20px' }}
                  >
                   View
                  </Button>
                  {/* <Button
                    onClick={() => handleAddResult(params?.row)}
                    variant="contained"
                    style={{ minWidth: '85px', backgroundColor: "#000000" }}
                  >
                    Add Result
                  </Button> */}
                </div>
              ),
            };
          }
          return col;
        })}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}