import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, FormControl, Box, Select, MenuItem, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyGroups } from 'src/redux/actions/group.action';
import { fetchUserData } from 'src/redux/actions/auth.action';

import merge from 'lodash/merge';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import CustomToggleSwitch from 'src/components/buttons/CustomToogleSwitch';
import CustomSearchBar from 'src/components/global/CustomSearchBar';
import SearchIcon from '@mui/icons-material/Search';
import ViewStudents from 'src/components/students/ViewStudents';
import AddStudent from 'src/components/students/AddStudent';
import { getStudents } from 'src/redux/actions/student.action';

import CampaignCard from 'src/components/listcards/campaign-card';

import redboy from 'src/assets/images/redboy.jpeg';
import greenboy from 'src/assets/images/greenboy.jpeg';
import athlete from 'src/assets/images/athlete.jpeg';
import amfootball from 'src/assets/images/amfootball.jpeg'


export default function StudentPage() {
  const theme = useTheme();
    
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);
  const { students } = useSelector((state) => state.student);

 // useEffect(() => {
 //   dispatch(fetchMyGroups(user?.coolers));
 //   dispatch(fetchMyTransactions(user?.id));
 //   console.log("Transac Changed.");
 // }, [user])
//
 // useEffect(() => {
 //   dispatch(getStudents());
 //   dispatch(fetchUserData(user?.id));
 // }, [])



  const [selectedOption, setSelectedOption] = useState('');
  const [activeButton, setActiveButton] = useState('viewStudents');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleViewStudentsClick = () => {
    setActiveButton('viewStudents');
 
  };

  const handleAddStudentsClick = () => {
    setActiveButton('addStudents');
  };

  return (
    <>


      <Helmet>
        <title> Vimondo </title>
      </Helmet>

      <Container maxWidth="xl" >
        <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{marginTop:"0.3rem"}}>
     <CustomToggleSwitch activeButton={activeButton} setActiveButton={setActiveButton} handleViewStudentsClick={handleViewStudentsClick} handleAddStudentsClick={handleAddStudentsClick}/>

          </Grid>
          <br/>
          
        <Grid container spacing={2}>
            <Grid item xs={8} md={12} lg={12}>
              <div style={{background: 'white',  padding: '10px',display:"flex",flexDirection:"column",gap:"1rem"}}>
               {/*activeButton === 'viewStudents' &&  <ViewStudents students={students}/>*/}  
              
               {/*activeButton === 'addStudents' && <AddStudent />*/}

               <CampaignCard pic={redboy} collection={'NORMAN STEVEN'} about={"Clark University | Football | Sophmore"} signed={"Signed: 01-01-2024 | expire: 01-01-2026"}/>
               <CampaignCard pic={greenboy} collection={'GRACE KENNETH'} about={"Morehouse University | Basketball | Senior"} signed={"Signed: 01-01-2024 | expire: 01-01-2026"}/>
               <CampaignCard pic={amfootball} collection={'CHRIS STONES'} about={"Mercer University | Football | Senior"} signed={"Signed: 01-01-2024 | expire: 01-01-2026"}/>
               <CampaignCard pic={athlete} collection={'KENNEDY FISHER'} about={"Bradley University | Football | Freshman"} signed={"Signed: 01-01-2024 | expire: 01-01-2026"}/>
                </div>
            </Grid>
            
          </Grid>
      </Container>
    </>
  );
}
