import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import CoolersPage from './pages/CoolersPage';
import MyCoolersPage from './pages/MyCoolersPage';
import PublicCoolerPage from './pages/PublicCoolerPage';
import PrivateCoolerPage from './pages/PrivateCoolerPage';
import MembersPage from './pages/MembersPage';
import CreateCoolerPage from './pages/CreateCoolerPage';
import PublicCoolerJoin from './pages/PublicCoolerJoin';
import PrivateCoolerJoin from './pages/PrivateCoolerJoin';
import RegisterPage from './pages/RegisterPage';
import JoinCoolerPage from './pages/JoinCoolerPage';
import InboxPage from './pages/InboxPage';
import SettingsPage from './pages/SettingsPage';
import UploadVideoPage from './pages/UploadVideo';
import StudentPage from './pages/StudentPage';
import ReportPage from './pages/ReportPage';
import ViewStudentPage from './pages/ViewStudentPage';
import TeacherPage from './pages/TeacherPage';
import EditStudentPage from './pages/EditStudentPage';
import EditTeacherPage from './pages/EditTeacherPage';
import ViewExamReportPage from './pages/ViewExamReportPage';
import PitchesPage from './pages/PitchesPage';
import IndividualCampaignPage from './pages/IndividualCampaignPage';
import ProfilePage from './pages/ProfilePage';
import WelcomePage from './pages/WelcomePage';
import LoginPageAthlete from './pages/LoginPageAthlete';
import PitchCreatedPage from './pages/PitchCreatedPage';
import CreatePitchPage from './pages/CreatePitchPage';
import AgreementsPage from './pages/AgreementsPage';
import HomePageAthlete from './pages/HomePageAthlete';
import DealsPage from './pages/DealsPage';
import HomeAthletePage from './pages/HomeAthletePage';
import LogoutPage from './pages/LogoutPage';
import AthleteProfilePage from './pages/AthleteProfilePage';
import CreatePitchBrandCollabsPage from './pages/CreatePitchBrandCollabsPage';
import CreatePitchGeneralInfoPage from './pages/CreatePitchGeneralInfoPage';


export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: 'home', element: <HomePage /> },

        //ATHLETE PAGES
        { path: 'home-athlete', element: <HomeAthletePage /> },
        { path: 'agreements', element: <AgreementsPage /> },
        { path: 'create-pitch-page', element: <CreatePitchPage /> },
        { path: 'create-pitch-general-info-page', element: <CreatePitchGeneralInfoPage /> },
        { path: 'create-pitch-brand-collabs-page', element: <CreatePitchBrandCollabsPage /> },
        

        { path: 'pitch-created-page', element: <PitchCreatedPage /> },
      //ATHLETE PAGES END

        { path: 'deals', element: <StudentPage /> },
        { path: 'athlete-profile', element: <AthleteProfilePage /> },
        { path: 'individual-campaign', element: <IndividualCampaignPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'view-student', element: <ViewStudentPage /> },
        { path: 'view-exam-report', element: <ViewExamReportPage /> },
        { path: 'edit-student', element: <EditStudentPage /> },
        { path: 'pitches', element: <PitchesPage /> },
        { path: 'edit-teacher', element: <EditTeacherPage /> },
        { path: 'reports', element: <ReportPage /> },
        { path: 'members', element: <MembersPage /> },
        { path: 'my-cooler', element: <MyCoolersPage /> },
        { path: 'cooler', element: <CoolersPage /> },
        { path: 'join-cooler', element: <JoinCoolerPage /> },
        { path: 'chat', element: <InboxPage /> },
        { path: 'settings', element: <SettingsPage /> },
        // { path: 'my-cooler', element: <MyCoolersPage /> },
        { path: 'public-cooler', element: <PublicCoolerPage /> },
        { path: 'private-cooler', element: <PrivateCoolerPage /> },
        { path: 'join-public-cooler', element: <PublicCoolerJoin /> },
        { path: 'join-private-cooler', element: <PrivateCoolerJoin /> },
        { path: 'create-cooler', element: <CreateCoolerPage /> },
        { path: 'upload-video', element: <UploadVideoPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      path: 'login-athlete',
      element: <LoginPageAthlete />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: '/',
      index: true, 
      element: <WelcomePage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
